import { template as template_13b7114eef5b4429999fa319b6f4260c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_13b7114eef5b4429999fa319b6f4260c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
