import { template as template_22098f439dfc43e2a7291d3ec6e0f09d } from "@ember/template-compiler";
const FKLabel = template_22098f439dfc43e2a7291d3ec6e0f09d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
