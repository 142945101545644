import { template as template_4db2945e697c4d8fb58f4e90ecb5a404 } from "@ember/template-compiler";
const WelcomeHeader = template_4db2945e697c4d8fb58f4e90ecb5a404(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
